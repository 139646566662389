import { VStack, Text, Center } from "@chakra-ui/react";
import { Navigate, Outlet, redirect } from "react-router";
import { createBrowserRouter } from "react-router-dom";

export const Root = () => {
  const browserLang = navigator.language || navigator.languages[0];
  let langCode = "";
  if (browserLang.includes("ja")) {
    langCode = "ja";
  } else {
    langCode = "en";
  }

  return (
    <VStack>
      <Navigate to={`/top/${langCode}`} replace />
      <Outlet />
    </VStack>
  );
};

const ErrorBoundary = () => (
  <Center>
    <Text>エラーが発生しました</Text>
  </Center>
);
const NotFoundPage = () => (
  <Center>
    <Text>お探しのページは存在しません</Text>
  </Center>
);

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      // "/" へのアクセス時に "top" へリダイレクト
      {
        path: "/",
        loader: () => redirect("/top/:lng"),
      },
      {
        path: "/top/:lng",
        async lazy() {
          try {
            const { Top } = await import("./pages/Top");
            return { Component: Top };
          } catch (_) {
            window.location.reload();
          }
          return { Component: NotFoundPage };
        },
      },
    ],
  },
  {
    path: "/privacy_policy/:lng",
    async lazy() {
      try {
        const { PrivacyPolicy } = await import("./pages/PrivacyPolicy");
        return { Component: PrivacyPolicy };
      } catch (_) {
        window.location.reload();
      }
      return { Component: NotFoundPage };
    },
  },
  {
    path: "/conditions/:lng",
    async lazy() {
      try {
        const { Conditions } = await import("./pages/Conditions");
        return { Component: Conditions };
      } catch (_) {
        window.location.reload();
      }
      return { Component: NotFoundPage };
    },
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
