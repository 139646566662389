import { RouterProvider } from "react-router";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import { router } from "./Root";

function App() {
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
